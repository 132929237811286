import React, { lazy, Suspense } from 'react';
import SEO from '../../components/common/SEO';
import Navbar from '../../components/Navbar';
import LandingHero from './components/LandingHero';

// Lazy load less critical components
const LandingSocialProof = lazy(() => import('./components/LandingSocialProof'));
const LandingBenefits = lazy(() => import('./components/LandingBenefits'));
const LandingHowItWorks = lazy(() => import('./components/LandingHowItWorks'));
const LandingCaseStudies = lazy(() => import('./components/LandingCaseStudies'));
const LandingFAQ = lazy(() => import('./components/LandingFAQ'));
const LandingCTA = lazy(() => import('./components/LandingCTA'));
const Footer = lazy(() => import('../../components/Footer'));

const LandingPage = () => {
  return (
    <div className="min-h-screen">
      <SEO 
        title="CGPT - Transform Your Business with AI"
        description="CGPT helps businesses leverage artificial intelligence to improve efficiency, reduce costs, and drive growth."
        canonical="https://yourdomain.com"
      />
      
      <Navbar />
      
      {/* Hero is not lazy loaded as it's above the fold */}
      <main>
        <section id="hero">
          <LandingHero />
        </section>

        <Suspense fallback={<div className="h-96 flex items-center justify-center">Loading...</div>}>
          {/* Social Proof Section */}
          <section id="social-proof" className="bg-gray-50 py-16">
            <div className="container mx-auto px-4 max-w-6xl">
              <LandingSocialProof />
            </div>
          </section>

          {/* Benefits Section */}
          <section id="benefits" className="py-16">
            <div className="container mx-auto px-4 max-w-6xl">
              <LandingBenefits />
            </div>
          </section>

          {/* Continue with other sections... */}
          
          <Footer />
        </Suspense>
      </main>
    </div>
  );
};

export default LandingPage;