import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAppSelector } from '../../hooks/useRedux';

// Configuration for which routes require which subscription plans
const SUBSCRIPTION_REQUIREMENTS = {
  '/dashboard': ['basic', 'pro', 'enterprise'],
  '/analytics': ['pro', 'enterprise'],
  '/settings': ['basic', 'pro', 'enterprise'],
  '/admin': ['enterprise'],
  // Add more route-plan mappings as needed
};

// Routes that are accessible during grace period after subscription expires
const GRACE_PERIOD_ROUTES = ['/dashboard', '/settings'];

const SubscriptionGuard = ({ children }) => {
  const location = useLocation();
  const subscription = useAppSelector(state => state.subscription);
  const isAuthenticated = useAppSelector(state => state.auth.isAuthenticated);
  
  console.log('SubscriptionGuard Check:', {
    subscription,
    currentPath: location.pathname,
    requirements: SUBSCRIPTION_REQUIREMENTS[location.pathname]
  });

  const getCurrentPath = () => {
    // Get the base path without query parameters
    return '/' + location.pathname.split('/')[1];
  };

  const isValidSubscription = () => {
    if (!subscription) return false;
    
    const currentPath = getCurrentPath();
    const requiredPlans = SUBSCRIPTION_REQUIREMENTS[currentPath];
    
    // If no requirements specified, allow access
    if (!requiredPlans) return true;
    
    // Check if user's plan is in the required plans list
    const hasPlan = requiredPlans.includes(subscription.currentPlan);
    
    // Check subscription status
    const isActive = subscription.status === 'active';
    const isCanceled = subscription.cancelAtPeriodEnd;
    
    // Calculate if within grace period (7 days after expiration)
    const periodEnd = new Date(subscription.currentPeriodEnd);
    const now = new Date();
    const gracePeriodEnd = new Date(periodEnd);
    gracePeriodEnd.setDate(gracePeriodEnd.getDate() + 7);
    const isInGracePeriod = now <= gracePeriodEnd;
    
    console.log('Subscription Validation:', {
      hasPlan,
      isActive,
      isCanceled,
      isInGracePeriod,
      currentPath,
      requiredPlans
    });
    
    // Allow access if:
    // 1. Subscription is active and has required plan
    // 2. Subscription is canceled but hasn't ended yet
    // 3. Within grace period for certain routes
    return (
      (isActive && hasPlan) ||
      (isCanceled && now <= periodEnd && hasPlan) ||
      (isInGracePeriod && GRACE_PERIOD_ROUTES.includes(currentPath))
    );
  };

  const getRedirectPath = () => {
    if (!isAuthenticated) {
      return '/signin';
    }
    
    if (!subscription) {
      return '/subscribe';
    }
    
    const currentPath = getCurrentPath();
    
    // If subscription expired, send to subscription page
    if (subscription.status !== 'active') {
      return '/subscribe';
    }
    
    // If plan doesn't have access to this route, send to upgrade page
    if (!isValidSubscription()) {
      return `/upgrade?from=${encodeURIComponent(location.pathname)}`;
    }
    
    return currentPath;
  };

  // Special case: always allow access to subscribe page
  if (location.pathname === '/subscribe') {
    return children;
  }

  if (!isValidSubscription()) {
    console.log('SubscriptionGuard: Redirecting to', getRedirectPath());
    return <Navigate to={getRedirectPath()} state={{ from: location }} replace />;
  }

  return children;
};

export default SubscriptionGuard;