import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-gray-50 border-t border-gray-200">
      <div className="container mx-auto px-4 py-12">
        <div className="grid md:grid-cols-4 gap-8">
          {/* Logo and Description */}
          <div className="col-span-1">
            <h3 className="text-xl font-bold mb-4">Logo</h3>
            <p className="text-gray-600">
              {/* Add company description */}
            </p>
          </div>

          {/* Links Section 1 */}
          <div className="space-y-4">
            <h4 className="text-sm font-semibold text-gray-900">Links</h4>
            <ul className="space-y-2">
              <li><a href="#" className="text-gray-600 hover:text-gray-900">Link 1</a></li>
              <li><a href="#" className="text-gray-600 hover:text-gray-900">Link 2</a></li>
              <li><a href="#" className="text-gray-600 hover:text-gray-900">Link 3</a></li>
            </ul>
          </div>

          {/* Links Section 2 */}
          <div className="space-y-4">
            <h4 className="text-sm font-semibold text-gray-900">Links</h4>
            <ul className="space-y-2">
              <li><a href="#" className="text-gray-600 hover:text-gray-900">Link 1</a></li>
              <li><a href="#" className="text-gray-600 hover:text-gray-900">Link 2</a></li>
              <li><a href="#" className="text-gray-600 hover:text-gray-900">Link 3</a></li>
            </ul>
          </div>

          {/* Social Links */}
          <div className="space-y-4">
            <h4 className="text-sm font-semibold text-gray-900">Connect</h4>
            <div className="flex space-x-4">
              {/* Add social media icons/links */}
            </div>
          </div>
        </div>

        {/* Bottom Bar */}
        <div className="border-t border-gray-200 mt-12 pt-8 text-center text-gray-600">
          <p>&copy; {new Date().getFullYear()} Your Company. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;