import React from 'react';
import { motion } from 'framer-motion';

const LandingHero = () => {
  return (
    <div className="pt-24 pb-12 px-4">
      <div className="grid md:grid-cols-2 gap-12 items-center max-w-6xl mx-auto">
        {/* Left Column - Animated Content */}
        <motion.div 
          className="space-y-6"
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5 }}
        >
          <motion.p 
            className="text-sm font-semibold text-blue-600"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.2 }}
          >
            Welcome to CGPT
          </motion.p>

          <motion.h1 
            className="text-4xl md:text-5xl font-bold text-gray-900"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.3 }}
          >
            Your Headline Goes Here
          </motion.h1>

          <motion.h2 
            className="text-xl text-gray-600"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.4 }}
          >
            This is a placeholder for your compelling sub-headline.
          </motion.h2>

          <motion.button 
            className="bg-blue-600 text-white px-8 py-3 rounded-lg hover:bg-blue-700 transition-colors"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            Get Started Now
          </motion.button>
        </motion.div>

        {/* Right Column - Animated Visual */}
        <motion.div 
          className="rounded-lg overflow-hidden shadow-xl bg-blue-100 aspect-video flex items-center justify-center"
          initial={{ opacity: 0, x: 50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5 }}
        >
          <div className="text-blue-600 text-xl">Video/Visual Content Here</div>
        </motion.div>
      </div>
    </div>
  );
};

export default LandingHero;