import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import DashboardLayout from '../../components/Layouts/DashboardLayout';
import { 
  ChartBarIcon, 
  UserGroupIcon, 
  ClockIcon, 
  DocumentTextIcon 
} from '@heroicons/react/solid';

const Dashboard = () => {
  const { user } = useAuth0();

  const stats = [
    { name: 'Total Projects', stat: '12', icon: DocumentTextIcon },
    { name: 'Active Users', stat: '3', icon: UserGroupIcon },
    { name: 'Time Saved', stat: '24h', icon: ClockIcon },
    { name: 'Efficiency', stat: '98%', icon: ChartBarIcon },
  ];

  return (
    <DashboardLayout>
      <div className="py-6">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          <h1 className="text-2xl font-semibold text-gray-900">Dashboard</h1>
        </div>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          {/* Welcome Section */}
          <div className="py-4">
            <div className="bg-white rounded-lg shadow px-5 py-6 sm:px-6">
              <h2 className="text-lg font-medium text-gray-900">
                Welcome back, {user?.name}
              </h2>
              <p className="mt-1 text-sm text-gray-500">
                Here's what's happening with your projects today.
              </p>
            </div>
          </div>

          {/* Stats Section */}
          <div className="mt-8">
            <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
              {stats.map((item) => (
                <div
                  key={item.name}
                  className="bg-white overflow-hidden shadow rounded-lg"
                >
                  <div className="p-5">
                    <div className="flex items-center">
                      <div className="flex-shrink-0">
                        <item.icon className="h-6 w-6 text-gray-400" aria-hidden="true" />
                      </div>
                      <div className="ml-5 w-0 flex-1">
                        <dl>
                          <dt className="text-sm font-medium text-gray-500 truncate">{item.name}</dt>
                          <dd className="text-lg font-semibold text-gray-900">{item.stat}</dd>
                        </dl>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Recent Activity */}
          <div className="mt-8">
            <div className="bg-white shadow rounded-lg">
              <div className="px-5 py-5 border-b border-gray-200">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  Recent Activity
                </h3>
              </div>
              <div className="px-5 py-5">
                <div className="space-y-4">
                  {/* Activity items - placeholder for now */}
                  {[1, 2, 3].map((item) => (
                    <div key={item} className="flex space-x-3">
                      <div className="flex-shrink-0">
                        <div className="h-8 w-8 rounded-full bg-gray-200" />
                      </div>
                      <div className="min-w-0 flex-1">
                        <p className="text-sm text-gray-500">
                          Activity item {item}
                        </p>
                        <p className="text-sm text-gray-500">
                          2 hours ago
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default Dashboard;